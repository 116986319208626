import React, {Component} from 'react';
import ReactTable from 'react-table';

import Column from '../../../containers/BarCodes/Column/Column';
import NewLoader from '../../NewLoader/NewLoader';
import CheckBox from '../../UI/Checkbox/CheckBox';

import './ThirdTab.sass';
import 'react-table/react-table.css';
import withRouter from 'react-router/es/withRouter';

const MainTableFields = [
    {id: 1, fieldName: 'BAR-code', accessor: 'barCode'},
    {id: 2, fieldName: 'Phone', accessor: 'phone'},
    {id: 3, fieldName: 'Email', accessor: 'email'},
    {id: 4, fieldName: 'Name', accessor: 'name'},
    {id: 5, fieldName: 'Gender', accessor: 'gender'},
    {id: 6, fieldName: 'Age', accessor: 'age'},
    {id: 7, fieldName: 'Address', accessor: 'address'},
    {id: 8, fieldName: 'Area', accessor: 'area'},
    {id: 9, fieldName: 'Side', accessor: 'side'},
    {id: 10, fieldName: 'Row', accessor: 'row'},
    {id: 11, fieldName: 'Seat', accessor: 'seat'},
];
class ThirdTab extends Component {
    state = {
        dropdown: MainTableFields,
        MainColumnData: [],
        checkedFieldAccessors: {},
    };

    componentDidUpdate(prevProps) {
        if (prevProps.tableConfig !== this.props.tableConfig) {
        const { id, name, isConfigured, needIncludeHeader, ...columnConfig } = this.props.tableConfig;
        needIncludeHeader && this.props.handleChange("isLineIgnore", needIncludeHeader);
        const columnIndices = Object.values(columnConfig).filter((item) => item !== null);
        this.setState({ columnIndices });

        columnIndices.forEach((columnIndex) => {
            const item = {
                id: columnIndex + 1,
                accessor: MainTableFields[columnIndex].accessor,
                fieldName: MainTableFields[columnIndex].fieldName
            };
            const accessor = 'col' + columnIndex;

            this.clickDropdownItemHandler(item, accessor, isConfigured)
        });
        };
    };

    clickDropdownItemHandler = (item, accessor, isConfigured) => {
        let {checkedFieldAccessors, MainColumnData, dropdown} = this.state;
        const {tableColumns} = this.props.tableContent;

        const currentColunm = tableColumns.find(item => item.columnIndex === parseInt(accessor.slice(3)));
        if (!currentColunm) {
            return
        };

        dropdown = dropdown.filter(dropdownItem => dropdownItem.id !== item.id);

        if(MainColumnData.length === 0){
            MainColumnData = currentColunm.columnValues.map(() => ({}));
        }

        if(checkedFieldAccessors[accessor]){
            MainColumnData = MainColumnData.map(item => {
                delete item[checkedFieldAccessors[accessor]];
                return item;
            });
            const usedMenuItem =  MainTableFields.find(item => item.accessor === checkedFieldAccessors[accessor])
            dropdown.push(usedMenuItem);
        }

        currentColunm.columnValues.forEach((value, index) => {
            MainColumnData[index][item.accessor] = value;
        });

        checkedFieldAccessors[accessor] = item.accessor;

        if (isConfigured) {
            this.setState((state) => {
                let columnDataToSet;

                if (!state.MainColumnData.length) {
                    columnDataToSet = MainColumnData;
                } else {
                    const MainColumnDataCopy = [...state.MainColumnData];
                    const updateMainColumnData = MainColumnDataCopy.map((columnItem, idx) => {
                        columnItem[item.accessor] = MainColumnData[idx][item.accessor];
                        return columnItem;
                    });

                    columnDataToSet = updateMainColumnData;
                };

                this.props.updateMainColumnData(columnDataToSet, checkedFieldAccessors);

                const dropdownToSet = state.dropdown.filter(dropdownItem => dropdownItem.id !== item.id);

                return {
                    dropdown: dropdownToSet,
                    MainColumnData: [...columnDataToSet],
                    checkedFieldAccessors,
                    selectedItem: item.fieldName
                }
            });
        } else {
            this.props.updateMainColumnData(MainColumnData, checkedFieldAccessors);
            this.setState({
                dropdown,
                MainColumnData,
                checkedFieldAccessors,
                selectedItem: item.fieldName
            });
        }
    };

    clearColumn = accessor => {
        let {checkedFieldAccessors, MainColumnData, dropdown} = this.state;
        if(checkedFieldAccessors[accessor]) {
            MainColumnData = MainColumnData.map(item => {
                delete item[checkedFieldAccessors[accessor]];
                return item;
            });
            const usedMenuItem =  MainTableFields.find(item => item.accessor === checkedFieldAccessors[accessor]);
            dropdown.push(usedMenuItem);

            delete checkedFieldAccessors[accessor];

            if(!Object.keys(MainColumnData[0]).length){
                MainColumnData = [];
            }

            this.props.updateMainColumnData(MainColumnData, checkedFieldAccessors);

            this.setState({
                dropdown,
                MainColumnData,
                checkedFieldAccessors,
            });
        }
    };

    // Remove from upper columns
    // removeFromMainTable = accessor => {
    //     const {checkedFieldAccessors,} = this.state;
    //     Object.keys(checkedFieldAccessors).forEach(key => {
    //         if(checkedFieldAccessors[key] === accessor) this.clearColumn(key);
    //     })
    // };

    checkboxChange = (evt) => {
        this.props.handleChange("isLineIgnore", evt.target.checked);
    };

    render() {
        const { tableContent, tableConfig, isLineIgnore } = this.props;
        const { columnIndices, dropdown, MainColumnData } = this.state;

        const mainTableHeaders = MainTableFields.map(field => ({
            Header: <Column title={field.fieldName}
                            accessor={field.accessor}
            />,
            accessor: field.accessor,
        }));

        const secondTableHeaders = tableContent ? tableContent.tableColumns.map(col => (
            {
                Header: col.columnIndex === 0 ?
                    <p className='ignore-msg'>Does your document have a headline?
                        <span>Should we ignore this line?</span>
                    </p> : '',
                accessor: 'col' + col.columnIndex,
                resizable: col.columnIndex === 0
            }
        )) : [];

        const thirdTableHeaders = (tableContent && columnIndices) || (tableContent && !tableConfig)
            ? tableContent.tableColumns.map(col => {
            const isSelectedItem = columnIndices && columnIndices.some(el => el === col.columnIndex);
            return {
                Header: <Column
                    toggleSubmenu={true}
                    dropdown={dropdown}
                    clickDropdownItemHandler={this.clickDropdownItemHandler}
                    accessor={'col' + col.columnIndex}
                    clearColumn={this.clearColumn}
                    selectedItem={isSelectedItem && MainTableFields[col.columnIndex].fieldName}
                />,
                accessor: 'col' + col.columnIndex,
            }
        }) : [];

        const columns = tableContent ?
            tableContent.tableColumns.reduce((accumulator, currentValue, currentIndex) => {

                currentValue.columnValues.forEach((columnValue, index) => {
                   if(!accumulator[index]){
                       accumulator[index] = {};
                   }

                   accumulator[index]['col' + currentIndex] = columnValue;

                });

                return accumulator;

            }, []) : [];

        return(
            <div className="third-tab">
                <div className="first-table">
                    <ReactTable
                        data={isLineIgnore ? MainColumnData.slice(1) : MainColumnData}
                        columns={mainTableHeaders}
                        showPagination={false}
                        minRows={0}
                        sortable={false}
                    />
                </div>

                {
                    columns.length ?
                    <>
                    <div className={`second-table ${isLineIgnore && 'ignore-line'}`}>
                        <CheckBox
                            name="isLineIgnore"
                            id="is-ignore"
                            checked={isLineIgnore}
                            onChange={this.checkboxChange}
                        >
                            Ignore this Line
                        </CheckBox>
                        <ReactTable
                            data={[columns[0]]}
                            columns={secondTableHeaders}
                            showPagination={false}
                            minRows={0}
                            sortable={false}
                        />
                    </div>

                    <div className="third-table">
                        <ReactTable
                            columns={thirdTableHeaders}
                            data={columns}
                            showPagination={false}
                            minRows={0}
                            sortable={false}
                            defaultPageSize={columns.length}
                        />
                    </div>
                    </>
                     : <NewLoader />
                }
            </div>
        );
    }
}

export default withRouter(ThirdTab);
