import * as actionTypes from "../actionTypes/emailTemplatesActionsTypes";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import ls from 'localstorage-slim';
import axios from "../../axios-instance";
import { getHeaders } from "../../helpers/getHeaders";
import { getOptionsForAsyncSelect } from "../../helpers/getOptionsForAsyncSelect";

//------------------ Get ALL Templates ------------------- 

const getEmailTemplatesStart = () => ({ type: actionTypes.GET_EMAIL_TEMPLATES });

const getEmailTemplatesSuccess = (templates) => ({
  type: actionTypes.GET_EMAIL_TEMPLATES_SUCCESS,
  payload: templates,
});

const getEmailTemplatesFail = () => ({
  type: actionTypes.GET_EMAIL_TEMPLATES_FAIL,
});

export const getEmailTemplates = (eventId) => (dispatch) => {
  dispatch(getEmailTemplatesStart());

  const user = ls.get("user");

  axios
    .get(`/EmailTemplate/EventTemplates/${eventId}`, { headers: getHeaders(user.token) })
    .then((response) => {
      if (response.data.isSuccess) {
        const dataForSelect = getOptionsForAsyncSelect(response.data.result, {
          labelKey: "name",
          returnDataKey: "item",
          valueKey: "id",
        });

        dispatch(getEmailTemplatesSuccess(dataForSelect));

        return response.data;
      };

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(getEmailTemplatesFail());

      Swal({
        title: "Error!",
        text: err,
        type: "error",
        timer: 3000,
      });
    });
};

//--------------- Edit Template --------------------

const editEmailTemplateStart = () => ({ type: actionTypes.EDIT_EMAIL_TEMPLATE });

const editEmailTemplateSuccess = () => ({
  type: actionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS,
});

const editEmailTemplateFail = () => ({
  type: actionTypes.EDIT_EMAIL_TEMPLATE_FAIL,
});

export const editEmailTemplate = (templateId, data, eventId) => async (dispatch) => {
  dispatch(editEmailTemplateStart());

  const user = ls.get("user");

  return axios
    .put(`/EmailTemplate/${templateId}`, data, {
      headers: getHeaders(user.token),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        toast.success("Template successfully edited");

        dispatch(getEmailTemplates(eventId));
        dispatch(editEmailTemplateSuccess());

        return response.data;
      };

      dispatch(editEmailTemplateFail());

      throw new Error(response.data.errorMessage);
    });
};

//--------------- Reset State -------------------

export const resetState = () => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_STATE,
  })
};
