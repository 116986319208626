import React, { Component } from "react";
import { toast } from "react-toastify";

import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../axios-instance";
import queryString from "query-string";
import moment from "moment";

import Container from "../../components/UI/Container/Container";
import Icon from "../../components/UI/Icon/Icon";
import NewLoader from "../../components/NewLoader/NewLoader";

import "./ResellingRequest.sass";
import ResellingRequestCard from "../../components/ResellingRequestCard/ResellingRequestCard";

import { showErrorModal } from "../../modals";

const MySwal = withReactContent(swal);

class ResellingRequest extends Component {
  state = {
    seasonPasses: [],
    selectedTickets: [],
    eventData: null,
    loading: true,
    resellingBlocked: false,
  };

  refuse = async () => {
    try {
      const eventId = this.props.match.params.eventId;
      const { customer: customerToken } = queryString.parse(
        this.props.location.search
      );
      const { selectedTickets } = this.state;
      this.setState({
        loading: true,
      });

      const body = {
        eventId,
        customerToken,
        seasonPassIds: selectedTickets,
      };

      const { data } = await axios.post("/SeasonRefuse/refuse", body);

      if (data) {
        this.getData();
      }
    } catch (e) {
      console.log(e); 
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again.`,
        customClass:
          "seasonpass__modal_info seasonpass__modal reselling-request__modal",
      });
    }
  };

  handleSubmit = async () => {
    MySwal.fire({
      title: "Are you sure you want to submit tickets for sale?",
      text: "This action is irreversible",
      customClass: "reselling-request__modal reselling-request__modal_confirm",
      cancelButtonText: "Cancel",
      confirmButtonText: "Submit",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.refuse();
      }
    });
  };

  handleSelect = (id) => {
    const { selectedTickets } = this.state;

    const isTicketAdded = selectedTickets.some(
      (selectedId) => selectedId === id
    );

    if (!isTicketAdded) {
      this.setState({ selectedTickets: [...selectedTickets, id] });
    } else {
      this.setState({
        selectedTickets: selectedTickets.filter(
          (selectedId) => selectedId !== id
        ),
      });
    }
  };

  setData = (eventData = {}, resellingData = {}) => {
    this.setState({
      eventData,
      seasonPasses: resellingData.seasonPasses || [],
      loading: false,
    });
  };

  checkIsResellingClosed = (eventData) => {
    const endDateMoment =
      eventData && eventData.endDate ? moment(eventData.endDate) : null;

    if (endDateMoment && moment().isAfter(endDateMoment)) {
      this.setState({ resellingBlocked: true });
      toast.info("Reselling for this event closed");
    }
  };

  getData = async () => {
    try {
      const eventId = this.props.match.params.eventId;
      const { customer: customerId } = queryString.parse(
        this.props.location.search
      );

      const params = {
        eventId,
        customerId,
      };

      const queryParams = queryString.stringify(params, { encode: false });

      const { data: resellingData } = await axios.get(
        `/SeasonRefuse/pass?${queryParams}`
      );
      const { data: eventData } = await axios.get(
        `EventPublicInfo/${eventId}`
      );

      this.checkIsResellingClosed(eventData);

      this.setData(eventData, resellingData);
    } catch (e) {
      console.log(e);
      showErrorModal({
        customClass:
          "seasonpass__modal_info seasonpass__modal reselling-request__modal",
        text: `Looks like an issue occurred during the page loading. Please try again later.`,
        showConfirmButton: false,
      });
    }
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    const {
      seasonPasses,
      eventData,
      loading,
      selectedTickets,
      resellingBlocked,
    } = this.state;

    if (loading) {
      return <NewLoader isFullScreen />;
    }

    if (!eventData) {
      return null;
    }

    const prefixLabel = eventData.prefixLabel;

    const location = eventData.place;

    const eventName = eventData.eventName;

    return (
      <div className="reselling-request__wrapper">
        <Container className="reselling-request">
          <div className="reselling-request__logo">
            <img src="/images/img-logo.svg" alt="Logo" />
          </div>
          <div
            className="reselling-request__header"
            style={{
              backgroundImage: `url(${eventData.imageUrl})`,
            }}
          >
            <h2 className="reselling-request__header-category">
              {prefixLabel}
            </h2>
            <h2 className="reselling-request__header-title">{eventName}</h2>
            <h5 className="reselling-request__header-subtitle">
              Supported by Fancee tickets
            </h5>
            <div className="reselling-request__header-location">
              <Icon name={"map-pin-solid"} />
              <p>{location}</p>
            </div>
          </div>
          <div className="reselling-request__hero">
            {seasonPasses.length &&
              seasonPasses.map((ticket) => (
                <ResellingRequestCard
                  key={ticket.id}
                  ticket={ticket}
                  selected={
                    !!selectedTickets.find(
                      (selectedId) => selectedId === ticket.id
                    )
                  }
                  resellingBlocked={resellingBlocked}
                  disabled={ticket.resellStatus !== 0}
                  handleSelect={this.handleSelect}
                />
              ))}
          </div>
          {!resellingBlocked && (
            <>
              <div className="reselling-request__coins">
                <span>
                  You will receive 35% of the daily ticket price once it’s sold.
                </span>
              </div>
              <div className="reselling-request__footer">
                <button
                  className="btn-primary"
                  type="button"
                  disabled={!selectedTickets.length}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </Container>
      </div>
    );
  }
}

export default ResellingRequest;
