import React, { useState } from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getCurrencieLabel } from "../../../../constants/currencies";
import { SelectTickets } from "../../../OpenSell/Steps";
import { isNoSeatTicket, isSeatsIoTicket } from "../../../../helpers/ticketHelper.ts";
import { getPrice } from "../../../../helpers/ticketPriceHelper.ts";
import { getTotal } from "../../helpers";
import { executePayment } from "../../../../helpers/paymentHelper/paymentHelper.ts";
import { getCurrentLanguageTexts } from "../../../../constants/seasonPassPersonalInfoLangs.js";

const MySwal = withReactContent(swal);

export const BuyTicketsOnFutureEventModal = ({ event, memberData, availiableSeatsCount, ...props }) => {
    const [state, setState] = useState({
        tickets: [],
        isLoading: false,
    });

    const txt = getCurrentLanguageTexts();

    const paymentAmount = getTotal(getPrice(state.tickets, 0), event.feeRate);

    const confirmHandler = () => {
        setState(v => {
            return {
                ...v,
                isLoading: true,
            }
        });

        executePayment({
            eventData: event,
            paymentMethod: 0, // kopa only
            couponCode: '',
            redirectUrl: `${window.location.href + "&"}`,
            tickets: state.tickets,
            transaction: {
                ...memberData,
            }
        })
    }

    const onTicketAdded = (position) => {
        setState(state => {
            if (isNoSeatTicket(position)) {
                let findedTicket = state.tickets.find(
                    ({ ticketTemplateId }) => ticketTemplateId === position.ticketTemplateId
                );

                if (findedTicket) {
                    return {
                        ...state,
                        tickets: state.tickets.map((ticket) =>
                            ticket.ticketTemplateId === position.ticketTemplateId ? position : ticket
                        ),
                    };
                }
            }
            if (isSeatsIoTicket(position) && !position.pricing) {
                return {
                    ...state,
                }
            }
            const tickets = [...state.tickets, position];

            return {
                ...state,
                tickets
            };
        })
    };

    const onObjectDeselected = (position) => {
        setState(state => {
            if (isNoSeatTicket(position)) {
                return (
                    {
                        ...state,
                        tickets: state.tickets.filter(
                            (item) => item.ticketTemplateId !== position.ticketTemplateId
                        ),
                    }
                );
            }
            else {
                return (
                    {
                        ...state,
                        tickets: state.tickets.filter((item) => item.id !== position.id),
                    }
                );
            }
        })
    };

    return <>
        <section>

            <SelectTickets
                feeRate={event.feeRate}
                ticketTemplates={event.ticketTemplates}
                maxSelectedObjects={Math.min(availiableSeatsCount, event.maxSeatsToSelectPerTransaction)}
                pricing={event.ticketTemplates.map(({ categoryKey, price }) => ({
                    category: categoryKey,
                    price,
                }))}
                tickets={state.tickets}
                onObjectSelected={onTicketAdded}
                onObjectDeselected={onObjectDeselected}
                hasSeats={true}
                workspaceKey={event.seatsIoWorkspaceId}
                eventSeatsId={event.seatsIoEventId}
                event={event}
                currency={getCurrencieLabel(event.currencyId)}
                total={paymentAmount}
                popupMode={true}
            />

        </section>

        <section className='swal2-actions'>

            <button className="swal2-cancel swal2-styled"
                onClick={() => MySwal.close()}>
                {txt.generalTxt.cancel}
            </button>

            <button disabled={!state.tickets.length || state.isLoading} className="swal2-confirm swal2-styled"
                onClick={confirmHandler}>
                {txt.generalTxt.buy}
            </button>

        </section>

    </>
}
