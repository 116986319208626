import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "../../axios-instance";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import ClipboardJS from "clipboard";

import Page404 from "../../components/Page404/Page404";
import Container from "../../components/UI/Container/Container";
import {
  approveTransaction,
  deleteTransaction,
  editTransaction,
  getTransactions,
  clearTransactions,
  deleteSeatsTransaction,
  cancelOfferTransaction,
} from "../../store/actions/TransactionsActions";
import NewLoader from "../../components/NewLoader/NewLoader";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import { paymentStatuses } from "../../constants/paymentStatuses";
import { StatusLabel } from "../../components/Transactions/StatusLabel/StatusLabel";
import ROUTES from "../../constants/routes";
import { getEvent, getEventSellStats } from "../../store/actions/eventsActions";
import { EditTransactionModal } from "./Modals/EditTransactionModal";
import { getHeaders } from "../../helpers/getHeaders";
import { useLazyLoadOnTableScroll } from "../../hooks/useLazyLoadOnTableScroll";
import { SeatsDescription } from "../../components/Transactions/SeatsDescription/SeatsDescription";
import { TransactionActions } from "../../components/Transactions/TransactionActions/TransactionActions";
import { RefundTicketsModal } from "./Modals/RefundTicketsModal";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { ApproveTransactionModal } from "./Modals/ApproveTransactionModal";
import { useShowActionsMenu } from "../../components/UI/ActionsMenu/hooks/useShowActionsMenu";
import { IconButton } from "../../components/UI/IconButton/IconButton";
import Icon from "../../components/UI/Icon/Icon";
import CheckBox from "../../components/UI/Checkbox/CheckBox.js";

import "react-table-hoc-fixed-columns/lib/styles.css";
import "react-table/react-table.css";
import "./Transactions.sass";
import { getPaymentTypeLabel, paymentTypes } from "../../constants/paymentType.ts";

const MySwal = withReactContent(Swal);

export const Transactions = ({
  transactions,
  getTransactions,
  approveTransaction,
  deleteTransaction,
  editTransaction,
  clearTransactions,
  deleteSeatsTransaction,
  cancelOfferTransaction,
  currentEvent,
  getEvent,
  match: { params },
}) => {
  const [user, setUser] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchByInputNumber, setSearchByInputNumber] = useState(false);
  const [searchPaymentStatus, setSearchPaymentStatus] = useState(null);
  const [maximizingOptions, setMaximizingOptions] = useState(true);
  const [sortingType, setSortingType] = useState(3);
  const [isEventFound, setIsEventFound] = useState(true);

  const pageSize = 50;
  const event_id = params.event_id || params.id;
  const { transactionsList, transactionsTotal, firstTransactionsLoading } =
    transactions;

  const ReactTableFixedColumns = useMemo(
    () => withFixedColumns(ReactTable),
    []
  );

  const [showMenuActions, hideMenuActions] = useShowActionsMenu();

  const [page, handleTableScroll, resetPage] = useLazyLoadOnTableScroll(
    pageSize,
    transactionsTotal
  );

  const handleTransactionsTableScroll = (e) => {
    handleTableScroll(e);
    hideMenuActions();
  };

  const handleOptionsClick = (e) => setMaximizingOptions((curr) => !curr);

  const changeSortingType = () => {
    resetPage();
    clearTransactions();
    setSortingType((curr) => (curr === 2 ? 3 : 2));
  };

  const changeSearchInputHandler = (e) => {
    resetPage();
    clearTransactions();
    setSearchQuery(e.target.value);
  };

  const changeSearchByInputNumberHandler = () => {
    resetPage();
    clearTransactions();
    setSearchByInputNumber(v => !v);
  };

  const changeSearchPaymentStatusHandler = (item) => {
    resetPage();
    clearTransactions();
    setSearchPaymentStatus(item);
  };

  const loadUser = () => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    setUser(currentUser);
  };

  const getEventTransactions = (userToken) =>
    getTransactions(
      userToken,
      searchQuery,
      searchByInputNumber,
      searchPaymentStatus == null ? [] : [searchPaymentStatus.value],
      event_id,
      "",
      page,
      pageSize,
      sortingType
    );

  const showApproveTransactionModal = (transaction) => {
    MySwal.fire({
      html: (
        <ApproveTransactionModal
          token={user.token}
          transactionId={transaction.id}
          approveTransaction={approveTransaction}
        />
      ),
      showConfirmButton: false,
      customClass: "approve-user-modal",
    });
  };

  const showDeleteTransactionModal = (transaction) => {
    MySwal.fire({
      title: "Are you sure you want to delete this transaction?",
      text: "It’s impossible to take step back then",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      reverseButtons: true,
      customClass: "delete-user-modal",
    }).then(({ value }) => {
      if (value) {
        deleteTransaction(user.token, transaction.id);
      }
    });
  };

  const showEditTransactionModal = (transaction) => {
    MySwal.fire({
      title: "Edit Information",
      html: (
        <EditTransactionModal
          token={user.token}
          editTransaction={editTransaction}
          deleteSeatsTransaction={deleteSeatsTransaction}
          transaction={transaction}
        />
      ),
      showConfirmButton: false,
      customClass: "edit-user-modal",
    });
  };

  const showRefundTicketsModal = (transaction) => {
    MySwal.fire({
      title: "Refund tickets",
      html: <RefundTicketsModal token={user.token} transaction={transaction} />,
      showConfirmButton: false,
      customClass: "refund-tickets-modal",
    });
  };

  const resendTicketsHandler = (transaction) => {
    axios
      .post(`/ManageTransactions/ResendTicket/${transaction.id}`, null, {
        headers: getHeaders(user.token),
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            toast.success("Tickets successfully resent");
          } else {
            toast.error("Tickets not sent");
          }
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Tickets not sent");
      });
  };

  const resendInvoiceHandler = (transaction) => {
    axios
      .post(
        `/ManageTransactions/ResendInvoice/${transaction.id}`,
        null,
        {
          headers: getHeaders(user.token),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Invoice successfully resent");
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        toast.error("Invoice not sent");
      });
  };

  const showLinkCopied = (link) => {
    ClipboardJS.copy(link);

    toast.dismiss(257);
    toast.success("Link was copied to clipboard.", {
      toastId: 257,
    });
  };

  const copyOfferLinkHandler = (transaction) => {
    showLinkCopied(
      `${process.env.REACT_APP_PUBLIC_URL}/offers/${transaction.id}`
    );
  };

  const copyTicketLinkHandler = (transaction) => {
    showLinkCopied(
      `${process.env.API_URL}/Transaction/Ticket/${transaction.ticketResultGuid}`
    );
  };

  const cancelOfferHandler = (transaction) => {
    MySwal.fire({
      title: "Are you sure you want to cancel offer?",
      text: "It’s impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      reverseButtons: true,
      customClass: "confirm-cancel-offer-modal",
    }).then((result) => {
      if (result) {
        cancelOfferTransaction(user.token, transaction);
      }
    });
  };

  useEffect(() => {
    if (user.token) {
      getEventTransactions(user.token);
    }
  }, [user, page, searchQuery, searchPaymentStatus, sortingType, searchByInputNumber]);

  useEffect(() => {
    if (user.token) {
      if (!currentEvent.id) {
        getEvent(event_id, user.token).then((res) => {
          if (!res.isFound) {
            setIsEventFound(false);
          }
        });
      }
    }
  }, [user]);

  useEffect(() => {
    clearTransactions();
    loadUser();
  }, []);

  const getLabelStyle = (state, rowInfo, column) => ({
    style: {
      marginTop: "6px",
    },
  });

  const columns = [
    {
      Header: "Name",
      accessor: "fullName",
      resizable: false,
      width: 180,
      getProps: getLabelStyle,
    },
    {
      Header: "Email",
      accessor: "buyerEmail",
      resizable: false,
      width: 280,
      getProps: getLabelStyle,
    },
    {
      Header: "Seats",
      accessor: "seatsDescription",
      resizable: false,
      width: 300,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: transaction } }) => (
        <SeatsDescription seats={transaction.seats} />
      ),
    },
    {
      Header: () => (
        <div className="payment-status" onClick={changeSortingType}>
          <span>Status</span>
          <Icon name="arrow-up-down" />
        </div>
      ),
      resizable: false,
      width: 135,
      Cell: ({
        row: {
          _original: {
            transactionErrorCode,
            transactionErrorMessage,
            paymentStatus,
            isOfferCanceled,
          },
        },
      }) => {
        const description =
          paymentStatus === 2
            ? transactionErrorMessage
              ? `${transactionErrorCode}: ${transactionErrorMessage}`
              : ""
            : "";

        const currentStatus = isOfferCanceled ? 3 : paymentStatus;

        return (
          <StatusLabel statusValue={currentStatus} description={description} />
        );
      },
    },
    {
      Header: "Payment Type",
      resizable: false,
      width: 220,
      Cell: ({ row: { _original: transaction } }) => <>{getPaymentTypeLabel(transaction.paymentType)}</>
    },
    {
      Header: "Transaction ID",
      accessor: "id",
      resizable: false,
      width: 320,
      getProps: getLabelStyle,
    },
    {
      Header: "Payment ID",
      accessor: "transactionId",
      resizable: false,
      width: 160,
      getProps: getLabelStyle,
    },
    {
      Header: () => {
        const optionsIconName = maximizingOptions
          ? "arrow-right"
          : "arrow-left";

        return (
          <div className="buttons-block options">
            <IconButton
              iconName={optionsIconName}
              className="btn-primary IconButton primary"
              onClick={handleOptionsClick}
            />
          </div>
        );
      },
      Cell: ({ row: { _original: transaction } }) => (
        <TransactionActions
          maximizingOptions={maximizingOptions}
          transaction={transaction}
          showDeleteTransactionModal={showDeleteTransactionModal}
          showApproveTransactionModal={showApproveTransactionModal}
          showEditTransactionModal={showEditTransactionModal}
          showRefundTicketsModal={showRefundTicketsModal}
          resendTicketsHandler={resendTicketsHandler}
          resendInvoiceHandler={resendInvoiceHandler}
          copyOfferLinkHandler={copyOfferLinkHandler}
          copyTicketLinkHandler={copyTicketLinkHandler}
          cancelOfferHandler={cancelOfferHandler}
          allowMaximize={(transaction.seats.length > 1) || (transaction.paymentType === paymentTypes.delayedPayment)}
        />
      ),
      width: maximizingOptions ? 280 : 83,
      fixed: "right",
    },
    {
      Header: "Creation Date",
      resizable: false,
      width: 320,
      Cell: ({ row: { _original: transaction } }) => {
        const date = new Date(transaction.createdAtUtc)
        return <>{
          date.toLocaleDateString()
        } {date.toLocaleTimeString()}</>
      }
    },
    {
      Header: "Invoice Number",
      resizable: false,
      width: 220,
      Cell: ({ row: { _original: transaction } }) => <>{transaction.invoice && transaction.invoice.number}</>
    },
    {
      Header: "Additional Info",
      resizable: false,
      width: 360,
      Cell: ({ row: { _original: transaction } }) => {
        return <>
          {transaction.paymentType === paymentTypes.delayedPayment
            ? <>
              <p>Company name: {transaction.companyName} </p>
              <p>Company VAT: {transaction.companyVatNumber} </p>
              <p>Invoice amount: {transaction.amount} {transaction.currency || 'RSD'}</p>
            </>
            : <>
              <p>transaction.transactionErrorMessage</p>
            </>
          }
        </>
      }
    },
  ];

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: "46px",
      "min-height": "46px",
      cursor: "pointer",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ABABAB",
    }),
  };

  let emptyStateText = "There are no transactions";

  if (transactionsTotal === 0 && searchQuery) {
    emptyStateText = "Sorry, no results were found";
  }

  const fullPath = [
    {
      path: ROUTES.EVENTS,
      name: "Events",
    },
    {
      path: ROUTES.EVENT.replace(":id", event_id),
      name: currentEvent.name,
    },
    {
      name: "Transactions",
    },
  ];

  if (!isEventFound) {
    return <Page404 />;
  }

  return (
    <Container className="transactions">
      <PageTitle>
        <Breadcrumbs routes={fullPath} />
      </PageTitle>

      <div className="searchPanel">
        <Select
          isClearable
          options={paymentStatuses}
          placeholder="Select payment status"
          name="searchPaymentStatus"
          classNamePrefix="seasonpass__select"
          value={searchPaymentStatus}
          onChange={changeSearchPaymentStatusHandler}
          className="seasonpass__select searchPanel__select"
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#6071B5",
              primary25: "#F2F4FE",
              primary50: "#F2F4FE",
              primary75: "#F2F4FE",
            },
          })}
        />

        <input
          className="searchPanel__search input-behavior"
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={changeSearchInputHandler}
        />

        <CheckBox checked={searchByInputNumber}
          id='searchByInputNumber'
          name="searchByInputNumber"
          onChange={changeSearchByInputNumberHandler}>
          <span className="searchPanel__search input-behavior">
            Search by invoice number
          </span>
        </CheckBox>

      </div>

      {firstTransactionsLoading ? (
        <NewLoader />
      ) : transactionsList.length ? (
        <>
          <ReactTableFixedColumns
            data={transactionsList}
            columns={columns}
            manual
            showPagination={false}
            minRows={0}
            sortable={false}
            getTrProps={() => ({
              style: {
                alignItems: "stretch",
              },
            })}
            getTableProps={() => ({
              onScroll: handleTransactionsTableScroll,
            })}
          />
        </>
      ) : (
        <div className="events__empty-state">
          <PageTitle>{emptyStateText}</PageTitle>
          <img src="/images/img-empty-events.svg" alt="Empty transactions" />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = ({ auth, transactions, events }) => ({
  auth,
  token: auth.user.token,
  transactions,
  currentEvent: events.currentEvent,
});

const mapDispatchToProps = (dispatch) => ({
  getTransactions: (
    userToken,
    searchQuery,
    searchByInputNumber,
    paymentStatuses,
    eventId,
    transactionStatus,
    page,
    pageSize,
    sortingType
  ) =>
    dispatch(
      getTransactions(
        userToken,
        searchQuery,
        searchByInputNumber,
        paymentStatuses,
        eventId,
        transactionStatus,
        page,
        pageSize,
        sortingType
      )
    ),
  approveTransaction: (userToken, id, makeFiscalization, onConfirm) =>
    dispatch(approveTransaction(userToken, id, makeFiscalization, onConfirm)),
  deleteTransaction: (userToken, id) =>
    dispatch(deleteTransaction(userToken, id)),
  editTransaction: (userToken, transaction) =>
    dispatch(editTransaction(userToken, transaction)),
  clearTransactions: () => dispatch(clearTransactions()),
  deleteSeatsTransaction: (userToken, transaction, seatIds) =>
    dispatch(deleteSeatsTransaction(userToken, transaction, seatIds)),
  cancelOfferTransaction: (userToken, transaction) =>
    dispatch(cancelOfferTransaction(userToken, transaction)),
  getEvent: (eventId, token) => dispatch(getEvent(eventId, token)),
  getEventSellStats: (eventId, token) =>
    dispatch(getEventSellStats(eventId, token)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Transactions)
);
