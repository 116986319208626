import React, { Component } from "react";
import { withRouter } from "react-router";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import queryString from "query-string";
import moment from "moment";
import axios from "../../axios-instance";

import Container from "../../components/UI/Container/Container";
import Icon from "../../components/UI/Icon/Icon";
import { Stepper } from "../../components/Stepper/Stepper";
import GuestModal from "./GuestModal/GuestModal";

import {
  FillFields,
  SelectTickets,
} from "../OpenSell/Steps";
import { ChoosePaymentMethod } from "./Steps/ChoosePaymentMethod";

import ROUTES from "../../constants/routes";
import { bankLogos, fee } from "../SeasonPass/constants";
import { currencies } from "../../constants/currencies";

import { getTotal } from "../SeasonPass/helpers";
import { getHeaders } from "../../helpers/getHeaders";
import { filterUnavailableTicketTemplates } from "../../helpers/filterUnavailableTicketTemplates";
import {
  mapSeat,
  mapSeatIo,
} from "../OpenSell/OpenSell.js";
import { showInfoModal, showErrorModal } from "../../modals";

import PleaseWait from "../../images/please-wait-season-pass-modal.svg";
import ThanksForYourPurchase from "../../images/thanks-for-your-purchase.svg";
import PartizanOpenSellingLogo from "../../images/partizan-open-selling-logo.svg";

import "../OpenSell/OpenSell.sass";
import "./Sell.sass";
import { isValidEmail } from "../../helpers/validators";
import Select from "react-select";
import { FramedLabel } from "../../components/FramedLabel/FramedLabel";
import NewLoader from "../../components/NewLoader/NewLoader";
import { isNfcTicket, isNoSeatTicket, isSeatsIoTicket } from "../../helpers/ticketHelper.ts";
import { getOc } from "../../helpers/optionalChain.ts";
import { getPrice, recalculatePrice } from "../../helpers/ticketPriceHelper.ts";

const MySwal = withReactContent(swal);

const initialState = {
  fields: {
    fullName: "",
    physicalAddress: "",
    city: "",
    postalCode: "",
    phoneNumber: "",
    buyerEmail: "",
    birthday: null,
    gender: "",
    generationTitle: "",
    companyName: "",
    paymentType: "",
    companyVatNumber: "",
  },
  tickets: [],
  transactionData: {
    orderId: "",
    paymentAmount: "",
    currencyCode: "RSD",
  },
  eventData: {
    ticketTemplates: [],
    reservations: [],
  },
  offers: [],
  step: 1,
  // UI color for page
  themeColor: "#6071B5",
  hasSeats: undefined,
  feeRate: fee,
  loading: true,
  submittBlocked: false,
  reservationsOptions: [],
  selectedReservation: 'Reservation',
  reservationOperationLoading: false,
  chart: null,
  mixedTickets: true,
  manualDiscount: 0,
  allowManualDiscount: true,
};

class Sell extends Component {
  state = initialState;

  formRef = React.createRef();

  handleChangeStep = (nextStep) => {
    this.setState({ step: nextStep });
  };

  setOrderDataToState = (data) => {
    const fields = {
      ...this.state.fields,
      fullName: data.fullName,
      physicalAddress: data.physicalAddress,
      city: data.city,
      postalCode: data.postalCode,
      phoneNumber: data.phoneNumber,
      buyerEmail: data.buyerEmail,

      birthday: data.birthday ? new Date(data.birthday) : null,
      gender: data.gender,
      companyName: data.companyName,
      companyVatNumber: data.companyVatNumber,
    };

    this.setState({
      fields,
    });
  };

  componentWillUnmount() {
    MySwal.close();
  }

  showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: "Please wait",
      text: "You will be redirected to the payment page",
      allowEscapeKey: false,
      customClass: "seasonpass__modal partizan-info-modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  showOfferConfirmationModal = (foo, relatedOffers, confirmButtonText) => {
    MySwal.fire({
      title: "Are you sure?",
      html: `
      You have selected the offered seats.
      <br/>
      Any actions with this seats will change existing offer
      <br/>
      <br/>
      Related offers: <ul style="line-height: 2rem;">${relatedOffers.reduce((a, i) => {
        return a + `<li style="text-align: left;"><strong>${i.seatLabel}</strong> for ${i.fullName} ${i.email}</li>`
      }, '')}</ul>`,
      allowEscapeKey: true,
      customClass: "seasonpass__modal partizan-info-modal",
      allowOutsideClick: true,
      allowEnterKey: false,
      reverseButtons: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      showCancelButton: true,
    }).then(foo);
  };

  getOrderData = async () => {
    try {
      const { data } = await axios.get(
        `Transaction/${this.props.match.params.id}`
      );
      if (data) {
        this.handleOrderStatuses(data);
        this.setOrderDataToState(data);
      }
    } catch (e) {
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        showConfirmButton: false,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
      console.log(e);
    }
  };

  setPrice = () => {
    const { tickets, manualDiscount } = this.state;

    recalculatePrice(tickets, manualDiscount);

    this.setTransactionData({
      paymentAmount: getPrice(tickets, manualDiscount),
    });
  };

  handleOrderStatuses = (data) => {
    const link = data.ticketResultGuid ? (
      <a
        className="sell__info-modal-link"
        target="__blank"
        href={`${process.env.API_URL}/Transaction/Ticket/${data.ticketResultGuid}?usedCached=true`}
      >
        Generated Pdfs
      </a>
    ) : (
      ""
    );

    const additionalText = data.ticketResultGuid
      ? `(Payment Status: ${data.additionalPaymentStatus
      }, Payment Date: ${moment(data.paymentDate).format(
        "DD MMMM YYYY HH:mm"
      )}, Transaction Id: ${data.transactionId})`
      : "";

    showInfoModal({
      onConfirm: (result) => {
        if (result) {
          const eventId = this.props.match.params.event_id;
          this.props.history.push({
            pathname: ROUTES.SELL.replace(":event_id", eventId).replace(
              ":id?",
              ""
            ),
          });
          window.location.reload();
        }
      },
      link,
      image: ThanksForYourPurchase,
      title: "Thanks for Your Purchase!",
      text: `We will send all the details to your inbox (${data.buyerEmail}) and look forward to meeting you`,
      customClass:
        "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      confirmButtonText: "Buy More",
      showConfirmButton: true,
      additionalText,
    });
  };

  setTransactionData = (data) => {
    const paymentAmount = data.paymentAmount;

    const orderId = data.orderId || this.state.transactionData.orderId || "";

    const transactionData = {
      ...this.state.transactionData,
      ...data,
      orderId,
    };

    if (paymentAmount) {
      transactionData.paymentAmount = getTotal(
        paymentAmount,
        this.state.feeRate
      );
    }

    this.setState({
      transactionData,
    });
  };

  showGuestModal = (ticketTemplateIds, generationTitle, ticketTemplates) => {
    MySwal.fire({
      html: (
        <GuestModal
          title={"Thanks for Your Purchase!"}
          ticketTemplateIds={ticketTemplateIds}
          generationTitle={generationTitle}
          ticketTemplates={ticketTemplates || []}
        />
      ),
      width: "900px",
      customClass:
        "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      allowEscapeKey: false,
      padding: "0px 0px 48px 0px",
      allowOutsideClick: false,
      allowEnterKey: false,
      confirmButtonText: "Buy More",
      showConfirmButton: true,
    }).then(() => {
      const eventId = this.props.match.params.event_id;
      this.props.history.push({
        pathname: ROUTES.SELL.replace(":event_id", eventId).replace(":id?", ""),
      });
      window.location.reload();
    });
  };

  checkGuestParams = () => {
    const { ticketTemplateIds, generationTitle } = queryString.parse(
      this.props.location.search
    );
    const { eventData } = this.state;

    if (!ticketTemplateIds || !generationTitle) {
      return false;
    }

    const parsedTicketTemplateIds = atob(ticketTemplateIds);
    const parsedGenerationTitle = atob(generationTitle);

    this.showGuestModal(
      parsedTicketTemplateIds.split(","),
      parsedGenerationTitle,
      eventData.ticketTemplates
    );

    return true;
  };

  guestCreate = (seats = []) => {
    const { generationTitle } = this.state.fields;
    const eventId = this.props.match.params.event_id;

    const uniqueIds = new Set();

    const ticketTemplateIds = seats
      .filter((item) => {
        if (!uniqueIds.has(item.ticketTemplateId)) {
          uniqueIds.add(item.ticketTemplateId);
          return true;
        }
        return false;
      })
      .map((item) => item.ticketTemplateId)
      .join(",");

    const params = {
      ticketTemplateIds: btoa(ticketTemplateIds),
      generationTitle: btoa(generationTitle),
    };

    const queryParams = queryString.stringify(params, { encode: false });

    this.props.history.push({
      pathname: `${ROUTES.SELL.replace(":event_id", eventId).replace(
        ":id?",
        ""
      )}?${queryParams}`,
    });

    this.checkGuestParams();
  };

  createOrder = async () => {
    const {
      fields: {
        paymentType,
        birthday,
        generationTitle,
        ...otherFields
      },
      transactionData,
      tickets,
      eventData,
    } = this.state;

    let seats = tickets.filter(t => isSeatsIoTicket(t)).map((ticket) => mapSeatIo({ ...ticket, eventData }))
      .concat(tickets.filter(t => isNoSeatTicket(t)).reduce(mapSeat, []))
      .concat(tickets.filter(t => isNfcTicket(t)).map((ticket) => {
        const [area, side] = ticket.masterTicket.labels.section.split("-");
        return {
          ticketTemplateId: ticket.ticketTemplateId,
          area: area,
          row: ticket.masterTicket.labels.parent,
          seat: ticket.masterTicket.labels.own,
          side: side,
          amount: ticket.price,
          fixedDiscount: (+ticket.originalPrice - +ticket.price).toFixed(2),
        }
      }));

    const eventId = this.props.match.params.event_id;

    const body = {
      transaction: {
        ...otherFields,
        currency: transactionData.currencyCode,
        amount: transactionData.paymentAmount,
        birthday: birthday ? birthday : undefined,
        seats,
        paymentType: paymentType,
      },
    };

    const user = JSON.parse(localStorage.getItem("user"));

    const params = {
      generationTitle,
    };

    const queryParams = queryString.stringify(params, { encode: false });

    const { data: id } = await axios.post(
      `ManageTransactions/CreateAndSell?${queryParams}`,
      body,
      {
        headers: getHeaders(user.token),
      }
    );

    if (paymentType === 4) {
      this.guestCreate(seats);
      return;
    }

    this.setTransactionData({
      orderId: id,
    });

    this.props.history.push({
      pathname: ROUTES.SELL.replace(":event_id", eventId).replace(":id?", id),
    });
  };

  handleSubmit = async () => {
    try {
      this.showPleaseWaitModal();
      await this.createOrder();

      await this.clearSelection();
      if (this.state.fields.paymentType !== 4) window.location.reload();
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
    }
  };

  handleChange = (name, value) => {
    if (name) {
      this.setState({ fields: { ...this.state.fields, [name]: value } });
    }
  };

  checkActionButtonDisabled = () => {
    const {
      step,
      fields: { buyerEmail, paymentType, generationTitle },
      submittBlocked,
      tickets,
      loading,
    } = this.state;

    if (buyerEmail && !isValidEmail(buyerEmail)) return true;

    if (submittBlocked || loading) return true;

    switch (step) {
      case 1:
        return !tickets.length;
      case 3:
        if (paymentType === 1) {
          return !paymentType;
        }
        if (paymentType === 4) {
          return !generationTitle || !paymentType;
        }
        return !paymentType;
      case 2:
        return !buyerEmail;
      default:
        return false;
    }
  };

  onTicketAdded = (position) => {
    if (isNoSeatTicket(position)) {
      let findedTicket = this.state.tickets.find(
        ({ ticketTemplateId }) => ticketTemplateId === position.ticketTemplateId
      );

      if (findedTicket) {
        this.setState(
          {
            tickets: this.state.tickets.map((ticket) =>
              ticket.ticketTemplateId === position.ticketTemplateId ? position : ticket
            ),
          },
          () => this.setPrice()
        );
        return;
      }
    }

    if (isSeatsIoTicket(position) && !position.pricing) {
      return;
    }
    const tickets = [...this.state.tickets, position];

    this.setState({ tickets }, () => this.setPrice());
  };

  onTicketRemoved = (position) => {
    if (isNoSeatTicket(position)) {
      this.setState(
        {
          tickets: this.state.tickets.filter(
            (item) => item.ticketTemplateId !== position.ticketTemplateId
          ),
        },
        () => this.setPrice()
      );
    }
    else if (isNfcTicket(position)) {
      this.setState(
        {
          tickets: this.state.tickets
            .filter((t) => t.id !== position.id),
        },
        () => this.setPrice()
      );
    }
    else {
      this.setState(
        {
          tickets: this.state.tickets
            .filter((t) => t.id !== position.id && getOc(t, 'masterTicket?.id') !== position.id),
        },
        () => this.setPrice()
      );
    }
  };

  getEventData = async () => {
    try {
      const eventId = this.props.match.params.event_id;
      if (!eventId) throw Error();

      const user = JSON.parse(localStorage.getItem("user"));

      document.documentElement.style.setProperty(
        "--partizan-theme",
        this.state.themeColor
      );

      const { data } = await axios.get(`EventPublicInfo/${eventId}`);

      data.ticketTemplates = filterUnavailableTicketTemplates(
        data.ticketTemplates
      );

      data.reservations.push({
        label: 'Open Sell',
        channelKey: 'NO_CHANNEL',
      })

      data.reservations = [{
        label: 'All',
        channelKey: data.reservations.map((r) => r.channelKey)
      }, ...data.reservations.map(r => { return { ...r, channelKey: [r.channelKey] } })];

      const { data: offersData } = await axios.get(`ManageTransactions/${eventId}/Offers`, { headers: getHeaders(user.token) });

      this.setState({
        transactionData: {
          ...this.state.transactionData,
          currencyCode: currencies.find(
            ({ value }) => value === data.currencyId
          ).label,
        },
        hasSeats: data.ticketTemplates.length
          ? data.ticketTemplates.filter(t => t.hasSeats) >= data.ticketTemplates.filter(t => !t.hasSeats)
          : undefined,
        feeRate: data.feeRate || fee,
        eventData: data,
        offers: offersData,
        reservationsOptions: data.reservations.map(r => {
          return {
            value: r.label,
            label: r.label,
          }
        })
      });
    } catch (e) {
      showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        showConfirmButton: false,
        customClass:
          "seasonpass__modal_info seasonpass__modal partizan-info-modal",
      });
      console.log(e);
    }
  };

  callAPIs = async () => {
    const orderId = this.props.match.params.id;
    this.setState({ loading: true });

    await this.getEventData();

    if (this.checkGuestParams()) {
      return;
    }

    if (orderId) await this.getOrderData();

    this.setState({ loading: false });
  };

  setReservationLoading = (value) => {
    this.setState({
      ...this.state,
      reservationOperationLoading: value,
    })
  }

  clearSelection = async (rerender = false) => {
    const chart = this.state.chart

    if (chart) {
      await chart.clearSelection();

      if (rerender) {
        chart.rerender();
      }
    }
  }

  reserveSeats = async () => {
    this.setReservationLoading(true);

    const channelId = this.state.eventData.reservations.find(r => r.label == 'Reservation').id;
    const user = JSON.parse(localStorage.getItem("user"));

    const seatsToProcessing = this.state.tickets.map(t => t.label);

    await axios.post(
      '/Reservation/assign',
      {
        reservationChannelId: channelId,
        seats: seatsToProcessing,
      },
      {
        headers: getHeaders(user.token),
      },);

    // this.setState({
    //   ...this.state,
    //    offers: this.state.offers.filter(o => !seatsToProcessing.includes(o.seatLabel))
    // });

    await this.clearSelection(true);

    this.setReservationLoading(false);
  }

  unreserveSeats = async () => {
    this.setReservationLoading(true);

    const user = JSON.parse(localStorage.getItem("user"));

    const seatsToProcessing = this.state.tickets.map(t => t.label);

    await axios.post(
      '/Reservation/unreserve',
      {
        eventId: this.state.eventData.eventId,
        seats: seatsToProcessing,
      },
      {
        headers: getHeaders(user.token),
      },);

    // this.setState({
    //   ...this.state,
    //   offers: this.state.offers.filter(o => !seatsToProcessing.includes(o.seatLabel))
    // });

    await this.clearSelection(true)

    this.setReservationLoading(false);
  }

  setChart = (c) => {
    this.setState(
      {
        ...this.state,
        chart: c,
      }
    )
  }

  getChannels = () => {
    let channels = this.state.eventData.reservations.find(r => r.label == this.state.selectedReservation)

    if (channels) {
      channels = channels.channelKey;
    }

    return !!channels ? channels : ['NO_CHANNEL'];
  }

  getSelectedOffers = () => {
    let offerChannel = this.state.eventData.reservations.find(r => r.label == 'Offers');

    offerChannel = offerChannel && offerChannel.channelKey[0];
    const offeredSeats = this.state.tickets.filter(t => getOc(t, 'isInChannel', () => false)(offerChannel)).map(t => t.label);

    return this.state.offers.filter(o => offeredSeats.includes(o.seatLabel))
  }

  componentDidMount() {
    this.callAPIs();
  }

  checkOfferConfirmation = (func, confirmButtonText) => {
    const offers = this.getSelectedOffers();

    if (offers.length) {
      this.showOfferConfirmationModal(r => 'value' in r ? func() : () => { }, offers, confirmButtonText);
    }
    else {
      func();
    }
  }

  render() {
    const {
      step,
      fields,
      tickets,
      transactionData,
      eventData,
      loading,
      hasSeats,
      feeRate,
      reservationsOptions,
      selectedReservation,
      manualDiscount,
      allowManualDiscount,
    } = this.state;

    const time = moment(eventData.startDate).format("DD.MM.YYYY HH:mm");

    const pricing = eventData.ticketTemplates.map(({ categoryKey, price }) => ({
      category: categoryKey,
      price,
    }));

    const prefixLabel = eventData.prefixLabel || "";

    const location = eventData.place || "";

    const steps = [
      {
        title: "Select Tickets",
        component: (
          <>
            {hasSeats &&
              <FramedLabel label='Reservation options'
                className='reservation-options'>

                <Select
                  options={reservationsOptions}
                  name="reservationChannel"
                  value={(() => {
                    const selected = reservationsOptions.find(
                      ({ label }) => selectedReservation == label
                    );
                    return selected
                  })()}
                  onChange={async (value) => {
                    await this.clearSelection();
                    this.setState({
                      ...this.state,
                      selectedReservation: value.label,
                    })
                  }}
                  classNamePrefix="partizan-open-selling__select"
                  className="partizan-open-selling__select"
                />

                <button onClick={(e) => {
                  e.preventDefault();
                  this.checkOfferConfirmation(this.reserveSeats, "Reserve")
                }}
                  className="btn-primary"
                  disabled={!tickets.length}>
                  Reserve</button>

                <button onClick={(e) => {
                  e.preventDefault();
                  this.checkOfferConfirmation(this.unreserveSeats, "Open to sale")
                }}
                  className="btn-primary"
                  disabled={!tickets.length}>
                  Open to sale
                </button>

                {this.state.reservationOperationLoading
                  && <NewLoader onClick={e => e.preventDefault()} />}



              </FramedLabel>
            }

            <SelectTickets
              maxSelectedObjects={100}
              feeRate={feeRate}
              tickets={tickets}
              ticketTemplates={eventData.ticketTemplates}
              pricing={pricing}
              hasSeats={hasSeats}
              workspaceKey={eventData.hideSeatsIoWorkspaceId}
              eventSeatsId={eventData.hideSeatsIoEventId}
              event={eventData}
              loading={loading}
              currency={transactionData.currencyCode}
              onObjectSelected={this.onTicketAdded}
              onObjectDeselected={this.onTicketRemoved}
              total={transactionData.paymentAmount}
              channels={this.getChannels()}
              showInfo={true}
              onSetChart={this.setChart}
              offers={this.state.offers}
              allowEnterCouponCode={false}
              reservations={this.state.eventData.reservations.filter(r => r.label != 'All').map(r => {
                return {
                  label: r.label,
                  channelKey: r.channelKey[0],
                }
              })}
              multiSelectEnabled={true}
              allowManualDiscount={allowManualDiscount}
              manualDiscount={manualDiscount}
              setManualDiscount={(value) => {
                this.setState(
                  {
                    ...this.state,
                    manualDiscount: Number(value)
                  }, () => this.setPrice())
              }}
            />
          </>
        ),
      },
      {
        title: "Fill in the info",
        component: (
          <FillFields
            fields={fields}
            requiredFields={["buyerEmail"]}
            shouldCheckCheckboxes={false}
            handleChange={this.handleChange}
            showTermsAndConditionsModal={this.showTermsAndConditionsModal}
            handleChangeCheckbox={this.handleChangeCheckbox}
          />
        ),
      },
      {
        title: "Buy tickets",
        component: (
          <ChoosePaymentMethod
            feeRate={feeRate}
            fields={fields}
            hasSeats={hasSeats}
            currency={transactionData.currencyCode}
            email={fields.buyerEmail}
            tickets={tickets}
            total={transactionData.paymentAmount}
            paymentType={fields.paymentType}
            handleChange={this.handleChange}
            allowEnterCouponCode={false}
          />
        ),
      },
    ];

    const headerComponent = (
      <div>
        <h2 className="partizan-open-selling__stepper-header-event-name">
          {prefixLabel}
        </h2>
        <h2 className="partizan-open-selling__stepper-header-title">
          {eventData.eventName}
        </h2>
        <h5 className="partizan-open-selling__stepper-header-subtitle">
          Supported by Fancee tickets
        </h5>
        <div className="partizan-open-selling__stepper-header-info">
          <div className="partizan-open-selling__stepper-header-info-location">
            <Icon name={"map-pin-solid"} />
            <p>{location}</p>
          </div>
          <p className="partizan-open-selling__stepper-header-info-time">
            {time}
          </p>
        </div>
      </div>
    );

    const actionComponent = (
      <div className="partizan-open-selling__stepper-action">
        <div className="partizan-open-selling__stepper-action-btns">
          <button
            className="btn-secondary"
            type="button"
            disabled={step === 1}
            onClick={() => this.handleChangeStep(step - 1)}
          >
            Previous
          </button>
          {steps.length === step && (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={this.handleSubmit}
            >
              Create
            </button>
          )}
          {steps.length !== step && (
            <button
              className="btn-primary"
              type="button"
              disabled={this.checkActionButtonDisabled()}
              onClick={() =>
                (step === 1)
                  ? this.checkOfferConfirmation(() => this.handleChangeStep(step + 1), "Next")
                  : this.handleChangeStep(step + 1)}
            >
              Next
            </button>
          )}
        </div>
        <div className="seasonpass__bank-logos">
          {bankLogos.map(({ alt, src, link }) => (
            <div key={alt}>
              <a target="_blank" rel="noopener noreferrer" href={link}>
                <img style={{ height: 30 }} src={src} alt={link} />
              </a>
            </div>
          ))}
        </div>
      </div >
    );

    return (
      <Container className="partizan-open-selling sell">

        <form ref={this.formRef} className="partizan-open-selling__wrapper">
          <div className="partizan-open-selling__logo seasonpass__header-logo-wrapper">
            <img src={PartizanOpenSellingLogo} alt="Logo" />
            <h2>Fancee Tickets</h2>
          </div>

          <Stepper
            headerBgStyle={{
              backgroundImage: `url(${eventData.imageUrl})`,
            }}
            headerWrapperClass="partizan-open-selling__stepper-header"
            headerComponent={headerComponent}
            actionComponent={actionComponent}
            currentStep={step}
            steps={steps}
          />

        </form>

      </Container >
    );
  }
}

export default withRouter(Sell);
