import ls from 'localstorage-slim';

const textsOnDifferentLangs = {
  en: {
    generalTxt: {
      cancel: "Cancel",
      save: "Save",
      confirm: "Confirm",
      buy: "Buy",
    },
    confirmSaveChanges: {
      title: "Are you sure you want save changes?",
      text: "It’s impossible to take step back then",
      btnConfirm: "Save",
      btnCancel: "Cancel",
    },
    handleOrderStatusesTxt: {
      thanksForYourPurchase: "Thanks for Your Purchase!",
      thanksText1: "We will send all the details to your inbox (",
      thanksText2: ") and look forward to meeting you",
      refusedText: "It seems to have failed to complete the payment, please try again",
    },
    pointsTable: {
      eventNameHeader: "Event Name",
      complexSeatHeader: "Complex Seat",
      pointsHeader: "Points",
      totalPoints: "Total Points",
    },
    sellPageTxt: {
      chooseYourSeatOnMap: "Choose Your Seat On Map",
      tickets: "Tickets",
      "Select a ticket on the map": "Select a ticket on the map",
      "Add a ticket from the list": "Add a ticket from the list",
      "Enter coupon code": "Enter coupon code",
      "Coupon code": "Coupon code",
    },
    buyMembershipTxt: {
      buyTitle: "Buy Membership",
      buyDescription: "Please make sure you selected the correct types of membership. After payment we will ask you to assign each membercard to Personal data (1 membercard per fan). (This membercard won’t give you any guaranties op seasonpass)",
      buyBtn: "Buy",
      cancelBtn: "Cancel",
      buyMembershipsListTitle: "Buy/Extend Membership",
      total: "Total:",
      iAgreeWith: "I agree with",
      termsOfService: "Terms of Service",
      buy: "Buy",
    },
    waitModalTxt: {
      title: "Please wait",
      description: "You will be redirected to the payment page",
      waitingForReview: "Waiting for review",
    },
    errorModalTxt: {
      description: "It seems to have failed to complete the payment, please try again"
    },
    deleteFileTxt: {
      title: "Are you sure you delete season pass?",
      description: "It’s impossible to take step back then",
      confirmBtn: "Delete",
      cancelBtn: "Cancel",
    },
    dataFormTxt: {
      buyBtn: "Buy",
      personalInfoTitle: "Personal Information",
      firstName: "First name",
      lastName: "Last name",
      email: "Email address",
      phone: "Phone number",
      streetAndHouse: "Street and House Number",
      city: "City",
      postalCode: "Postal code",
      year: "Year",
      month: "Month",
      day: "Day",
      saveChanges: "Save Changes"
    },
    SeasonPassListTxt: {
      title: "Bought Memberships",
      spListTitle: "Season Pass List",
      for: "for",
      buySeasonPass: "Buy season pass",
      seasonPassPurchased: "Season pass purchased",
      buyViaInvoice: "Buy all via invoice",
      toolTipResend: "Resend the ticket",
      tooltipPleaseWait: "Please wait"
    },
    memberEventsList: {
      "Future events in": "Future events in",
      "Buy tickets": "Buy tickets",
      "Your season passes": "Your season passes",
      "Your tickets": "Your tickets",
      "Buy more tickets": "Buy more tickets",
      "Show more": "Show more",
      "Your tickets on this event": "Your tickets on this event",
      "Season Pass": "Season Pass",
      "Tickets": "Tickets",
      "Cancelled": "Cancelled",
      resaleBlock: {
        "Send to resale": "Send to resale",
        "Selling": "In selling",
        "Sold": "Sold",
        "Total collected": "Total collected",
        "Seat sended to resale" : "Seat sended to resale",
        "Seat was not sent for resale":  "Seat was not sent for resale",
      },
      resaleApprovePopup:{
        title: "Are you sure you want to submit ticket for sale?",
        text: "This action is irreversible",
        "Send to resale": "Send to resale",
      }
    },
    EditMembershipCardInfoModalTxt: {
      title: "Edit membership card",
      placeholder: "Enter name",
      selectedSeat: "Selected seat",
      restoreButtonDescription: "Restore selected seat to default",
    },
    BuySeasonPassModalTxt: {
      title: "Buy season pass",
      buyPhysicalNFCCard: "Buy physical NFC card",
      NFCDescription: "Your pass will be in electronic form, if you want to buy a physical NFC card, click here",
      vatPlaceholder: "Company VAT",
    },
    SelectTicketTxt: {
      tickets: "Tickets",
      bookingFee: "Booking fee",
      usedPoints: "Used points",
      total: "Total",
      row: "Row",
      seat: "Seat",
    },
    BuyAllSeasonPassesModalTxt: {
      title: "Buy season passes",
      modalDescription: "You receive email with payment information",
      companyNameLabel: "Company name",
      companyNamePlaceholder: "Enter company name",
      companyVatLabel: "Company VAT number",
      companyVatPlaceholder: "Enter VAT number",
      companyAddressLabel: "Company address",
      companyCityPlaceholder: "Enter city",
      companyAddressPlaceholder: "Enter physical address",
      createInvoiceButton: "Request invoice",
    },
  },
  rs: {
    generalTxt: {
      cancel: "Otkaži",
      save: "Sačuvaj",
      confirm: "Potvrdi",
      buy: "Kupi",
    },
    confirmSaveChanges: {
      title: "Da li ste sigurni da želite sačuvati izmene?",
      text: "Naknadne izmene neće biti moguće",
      btnConfirm: "Sačuvaj",
      btnCancel: "Otkaži",
    },
    handleOrderStatusesTxt: {
      thanksForYourPurchase: "Uspešna kupovina. Hvala vam!",
      thanksText1: "Poslaćemo vam sve detalje na vašu mail adresu (",
      thanksText2: ") i radujemo se da vas vidimo",
      refusedText: "Kupovina nije uspešna, molimo, pokušajte ponovo",
    },
    pointsTable: {
      eventNameHeader: "Događaj",
      complexSeatHeader: "Sedište",
      pointsHeader: "Poeni",
      totalPoints: "Ukupno poena",
    },
    sellPageTxt: {
      chooseYourSeatOnMap: "Izaberite svoje mesto na mapi",
      tickets: "Ulaznice",
      "Select a ticket on the map": "Izaberite kartu na mapi",
      "Add a ticket from the list": "Dodajte kartu sa liste",
      "Enter coupon code": "Unesite kod kupona",
      "Coupon code": "Kod kupona",
    },
    buyMembershipTxt: {
      buyTitle: "Kupi člansku kartu",
      buyDescription: "Molimo vas da proverite da li ste odabrali odgovarajuće vrste članstva. Nakon plaćanja, zatražićemo od vas da dodelite lične podatke svakoj članskoj karti (jedna članska karta po osobi). (Ukoliko ste vlasnik sezonske ulaznice, kupovinom članske karte imate zagarantovano isto mesto u narednoj sezoni. Ukoliko ne posedujete sezonsku kartu, bićete na prioritetnoj listi za kupovinu nove.)",
      buyBtn: "Kupi",
      cancelBtn: "Otkaži",
      buyMembershipsListTitle: "Kupi/Obnovi članstvo",
      total: "Ukupno:",
      iAgreeWith: "Saglasan/na sam",
      termsOfService: "Uslovi korišćenja",
      buy: "Kupi"
    },
    waitModalTxt: {
      title: "Molimo sačekajte",
      description: "Bićete preusmereni na stranicu za plaćanje",
      waitingForReview: "Poslato na reviziju!",
    },
    errorModalTxt: {
      description: "Neuspešna kupovina, molimo pokušajte ponovo"
    },
    deleteFileTxt: {
      title: "Da li ste sigurni da želite da obrišete vašu sezonsku kartu?",
      description: "Nakon ovog koraka, naknadne izmene neće biti moguće",
      confirmBtn: "Obriši",
      cancelBtn: "Otkaži",
    },
    dataFormTxt: {
      buyBtn: "Kupi",
      personalInfoTitle: "Lični podaci",
      firstName: "Ime",
      lastName: "Prezime",
      email: "Email adresa",
      phone: "Kontakt telefon",
      streetAndHouse: "Ulica i broj",
      city: "Grad",
      postalCode: "Poštanski broj",
      year: "Godina rođenja",
      month: "Mesec",
      day: "Dan",
      saveChanges: "Sačuvaj izmene"
    },
    SeasonPassListTxt: {
      title: "Kupljene članske karte",
      spListTitle: "Sezonske karte",
      for: "za",
      buySeasonPass: "Kupite sezonsku kartu",
      seasonPassPurchased: "Sezonska karta je kupljena",
      buyViaInvoice: "Kupujte sve preko fakture",
      toolTipResend: "Ponovo pošaljite kartu",
      tooltipPleaseWait: "Molim sačekajte"
    },
    memberEventsList: {
      "Future events in": "Budući događaji u",
      "Buy tickets": "Kupite karte",
      "Your season passes": "Vaša sezona prolazi",
      "Your tickets": "Vaše karte",
      "Buy more": "Kupite više",
      "Buy more tickets": "Kupite još karata",
      "Show more": "Pokaži više",
      "Your tickets on this event": "Vaše karte za ovaj događaj",
      "Season Pass": "Sezonska Karta",
      "Tickets": "Ulaznice",
      "Cancelled": "Otkazano",
      resaleBlock: {
        "Send to resale": "Poslati na preprodaju",
        "Selling": "U prodaji",
        "Sold": "Prodato",
        "Total collected": "Ukupno prikupljeno",
        "Seat sended to resale" : "Sedište poslato na preprodaju",
        "Seat was not sent for resale":  "Sedište nije poslato na preprodaju",
      },
      resaleApprovePopup:{
        title: "Da li ste sigurni da želite da pošaljete kartu na prodaju?",
        text: "Ova akcija je nepovratna",
        "Send to resale": "Poslati na preprodaju",
      }
    },
    EditMembershipCardInfoModalTxt: {
      title: "Izmeni člansku kartu",
      placeholder: "Unesite ime",
      selectedSeat: "Izabrano sedište",
      restoreButtonDescription: "Vrati izabrano sedište na podrazumevano",
    },
    BuySeasonPassModalTxt: {
      title: "Kupite sezonsku kartu",
      buyPhysicalNFCCard: "Kupite fizicku NFC karticu",
      NFCDescription: "Vaša propusnica će biti u elektronskom obliku, ukoliko želite da kupite fizičku NFC karticu kliknite ovde",
      vatPlaceholder: "PIB",
    },
    SelectTicketTxt: {
      tickets: "Ulaznice",
      bookingFee: "Troškovi obrade",
      usedPoints: "Iskorišteni bodovi",
      total: "Ukupno",
      row: "Red",
      seat: "Sediste",
    },
    BuyAllSeasonPassesModalTxt: {
      title: "Kupite sezonske karte",
      modalDescription: "Dobijate imejl sa informacijama o plaćanju",
      companyNameLabel: "Naziv kompanije",
      companyNamePlaceholder: "Unesite naziv kompanije",
      companyVatLabel: "VAT broj preduzeća",
      companyVatPlaceholder: "Unesite VAT broj",
      companyAddressLabel: "Adresa kompanije",
      companyCityPlaceholder: "Unesite grad",
      companyAddressPlaceholder: "Unesite fizičku adresu",
      createInvoiceButton: "Zahtevajte fakturu",
    },
  },
};

export const getLangCode = () => {
  return ls.get('targetLang') || 'en';
}

export const setLangCode = (langCode) => {
  ls.set('targetLang', langCode)
}

export function getCurrentLanguageTexts() {
  const lang = getLangCode();

  let txt = textsOnDifferentLangs['en'];

  if (lang !== 'en') {
    txt = textsOnDifferentLangs[lang];
  }

  return txt;
}