export const getSeatTextDescription = (seat, needEmptyState = false) => {
  let description = "";

  if (seat.ticketTemplate && seat.ticketTemplate.ticketName) {
    description += `${seat.ticketTemplate.ticketName}, `;
  }
  else if (seat.ticketName) {
    description += `${seat.ticketName}, `;
  }

  if (seat.area) {
    description += `Area ${seat.area}`;
  }

  if (seat.row) {
    description += `, Row ${seat.row}`;
  }

  if (seat.seat) {
    description += `, Seat ${seat.seat}`;
  }

  if (seat.side) {
    description += ` ${seat.side}`;
  }

  if (needEmptyState && !description) {
    description = "-";
  }

  return description;
};
