import React from "react";

export const getSeatDescription = (seat) => {
  let description = <></>;

  if (seat.area) {
    description = (
      <>
        {description}Area - <b>{seat.area}</b>
      </>
    );
  }

  if (seat.row) {
    description = (
      <>
        {description}, Row - <b>{seat.row}</b>
      </>
    );
  }

  if (seat.seat) {
    description = (
      <>
        {description}, Seats - <b>{seat.seat}</b>
      </>
    );
  }

  if (seat.side) {
    description = (
      <>
        {description}, Place - <b>{seat.side}</b>
      </>
    );
  }

  if (seat.ticketTemplate.ticketName) {
    description = <>{description}, {seat.ticketTemplate.ticketName}</>;
  }

  return description;
};
