import React, { useEffect, useState } from "react";

import axios from "../../axios-instance.js";

import "./OpenSell.sass";
import { getCurrentLanguageTexts } from "../../constants/seasonPassPersonalInfoLangs.js";
import CheckBox from "../../components/UI/Checkbox/CheckBox.js";
import { entreeTicketToSeatsIointerface, getSeatSideLabel, hasSeatTicket, isSeatsIoTicket } from "../../helpers/ticketHelper.ts";

const renderSeatsIoTicket = (
  { currency, category: { label }, labels: { section, parent, own }, pricing, allowBuyNfc = false, isNfcChecked = false, handleNfcChange },
  i
) => {
  const [area, side] = section.split("-");
  const price = pricing.price || null;

  return (
    <div key={i} className="partizan-open-selling__selected-tickets-item">

      <div className="partizan-open-selling__selected-tickets-item-labels">

        <span className="partizan-open-selling__text-normal">
          {label} {area}, Row {parent} Seat {own} {() => side ? side : ''}
        </span>

        <span className="partizan-open-selling__text-bold price">
          {price} {currency}
        </span>

      </div>

      {allowBuyNfc &&
        <CheckBox
          name="buyNfcCard"
          id={`buy-nfc-card_${i}`}
          checked={isNfcChecked}
          onChange={(v) => {
            handleNfcChange(!isNfcChecked, `${section}-${parent}-${own}`)
          }}>
          Add NFC
        </CheckBox>}
    </div>
  );
};

const renderTicketWithSeats = ({ name, price, currency, count, couponData, area, side, row, seat }, i
) => {

  return (
    <div key={i} className="partizan-open-selling__selected-tickets-item">

      <div className="partizan-open-selling__selected-tickets-item-labels">

        <span className="partizan-open-selling__text-normal">
          {name}, {area} {getSeatSideLabel(side)}, Row {row} Seat {seat}
        </span>

        <span className="partizan-open-selling__text-bold price">
          {price} {currency}
        </span>

      </div>

    </div>
  );
};

const renderTicket = ({ name, price, currency, count, couponData }, i) => {
  return (
    <div key={i} className="partizan-open-selling__selected-tickets-item">
      <div className="partizan-open-selling__selected-tickets-item-labels">
        <span className="partizan-open-selling__text-normal">{name}</span>
        <span className="partizan-open-selling__text-bold price">
          {(price * count -
            (couponData && i == 0
              ? (price * (count > 2 ? 2 : count) * couponData.discount) / 100
              : 0)).toFixed(2)}{" "}
          {currency}
        </span>
      </div>
    </div>
  )
}

export const SelectedTickets = ({
  tickets = [],
  setCouponHandler,
  coupon,
  currency,
  total,
  feeRate,
  hasSeats,
  mixedTickets,
  event,
  ticketAdd,
  ticketRemove,
  allowEnterCouponCode = true,
  autoHeight = false,
  pointsForDiscount = 0,
  allowManualDiscount = false,
  manualDiscount = 0,
  setManualDiscount = () => null,
  popupMode = false,
}) => {
  const txt = getCurrentLanguageTexts();

  const [couponCode, setCouponCode] = useState(coupon ? coupon.barcode : "");
  const [couponData, setCouponData] = useState(coupon);

  useEffect(() => {
    setCouponData(coupon);
    setCouponCode(coupon ? coupon.barcode : "");
  }, [coupon]);


  const eventid = event ? event.eventId : 0;
  const isSeasonEvent = eventid == process.env.REACT_APP_NEW_SEASON_EVENT_ID;
  const nfcTicketTemplate = isSeasonEvent
    ? event.ticketTemplates.find(t => t.ticketTemplateId == process.env.REACT_APP_NEW_SEASON_NFC_CARD_TICKET_TEMPLATE_ID)
    : null;

  const handleCouponChanges = async (e) => {
    // e.preventDefault();
    // debounce(setSearch(e.target.value.toUpperCase()), 500)// debounce 500 milliseconds
    // console.log(couponCode);
    // console.log(event);

    const code = e.target.value.toLowerCase();

    setCouponCode(code);

    try {
      var response = await axios.get(
        "/Transaction/couponDetails?couponCode=" +
        code +
        "&eventId=" +
        event.eventId,
        {}
      );

      if (response.data && response.data.isSuccess) {
        setCouponData(response.data.result);
        setCouponHandler(response.data.result);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  if (!tickets.length) {
    return (
      <div className="partizan-open-selling__empty-tickets">
        <img src="/images/img-empty-events.svg" alt="Select Ticket" />
        <p>
          {hasSeats
            ? txt.sellPageTxt["Select a ticket on the map"]
            : txt.sellPageTxt["Add a ticket from the list"]}
        </p>
      </div>
    );
  }

  const hasSeat = (ticket) => {
    return isSeatsIoTicket(ticket);
  }

  const renderTickets = () => {
    const ticketFromMap = tickets.filter(t => hasSeat(t));
    const ticketNoSeats = tickets.filter(t => !hasSeat(t));

    return ticketFromMap.map((ticket, i) =>
      renderSeatsIoTicket({
        ...ticket,
        currency,
        allowBuyNfc: isSeasonEvent,
        isNfcChecked: ticket.isNfcChecked,
        handleNfcChange: (v, label) => {
          ticket.isNfcChecked = v;

          if (v) {
            ticket.nfc = entreeTicketToSeatsIointerface({}, nfcTicketTemplate);

            ticket.nfc.name = ticket.nfc.name + ` ${ticket.id}`;

            ticket.nfc.masterTicket = ticket;
            ticket.nfc.id = Math.random();

            if (ticketAdd) ticketAdd(ticket.nfc);
          }
          else if (ticket.nfc && ticketRemove) {
            ticketRemove(ticket.nfc);
          }
        }
      }, i)
    ).concat(ticketNoSeats.map((ticket, i) =>
      hasSeatTicket(ticket)
        ? renderTicketWithSeats({ ...ticket, currency: currency }, i)
        : renderTicket({
          ...ticket,
          currency,
          couponData
        }, i + ticketFromMap.length)
    ));
  };

  return (
    <>
      <div style={{ height: autoHeight ? 'auto' : '' }} className="partizan-open-selling__selected-tickets">
        <h4 className="partizan-open-selling__text-bold">{txt.SelectTicketTxt['tickets']}</h4>
        {renderTickets()}
      </div>

      <div className="partizan-open-selling__total">
        {feeRate > 0 && (
          <div>
            <span className="partizan-open-selling__text-bold-large">
              {txt.SelectTicketTxt['bookingFee']}:
            </span>
            <span className="partizan-open-selling__text-bold-large">
              {feeRate * 100}%
            </span>
          </div>
        )}

        {pointsForDiscount
          ? <div>
            <span className="partizan-open-selling__text-bold-large">{txt.SelectTicketTxt['usedPoints']}:</span>
            <span className="partizan-open-selling__text-bold-large">
              -{pointsForDiscount}
            </span>
          </div>
          : ''
        }

        <div>
          <span className="partizan-open-selling__text-bold-large">{txt.SelectTicketTxt['total']}:</span>
          <span className="partizan-open-selling__text-bold-large">
            {total} {currency}
          </span>
        </div>
      </div>
      {allowEnterCouponCode && (
        <div className="partizan-open-selling__input_block">
          <label>{txt.sellPageTxt["Coupon code"]}</label>
          <input
            className="seasonpass__field-input"
            placeholder={txt.sellPageTxt["Enter coupon code"]}
            name="couponCode"
            onChange={(e) => handleCouponChanges(e)}
            type="text"
            value={couponCode}
            disabled={couponData}
          />
        </div>
      )}

      {allowManualDiscount && (
        <div className="partizan-open-selling__input_block">
          <label>Manual discount</label>
          <input
            className="seasonpass__field-input"
            placeholder="Enter fixed discount"
            name="manualDiscount"
            onChange={(e) => setManualDiscount(e.target.value)}
            type="number"
            value={manualDiscount ? Number(manualDiscount).toString() : ''}
          />
        </div>
      )}
    </>
  );
};
