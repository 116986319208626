import React, { Fragment } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import withRouter from "react-router/es/withRouter";

import Scanbits from "../Scanbits/Scanbits";
import ROUTES from "../../constants/routes";
import Faq from "../../containers/FAQ/FAQ";
import AddEvent from "../../containers/Events/AddEvent/AddEvent";
import Events from "../../containers/Events/Events";
import Event from "../../containers/Events/Event/Event";
import Sidebar from "../Sidebar/Sidebar";
import TicketDetail from "../../containers/TicketDetail/TicketDetail";
import ScanTeams from "../../containers/ScanTeams/ScanTeams";
import Payments from "../../containers/Payments/Payments";
import AddTeams from "../../containers/ScanTeams/AddTeams/AddTeams";
import Promoters from "../../containers/Promoters/Promoters";
import BarCodes from "../../containers/BarCodes/BarCodes";
import Page404 from "../Page404/Page404";
import EventStatistics from "../../containers/EventStatistics/StatisticPage";
// import Dashboard from "../../containers/Dashboard/Dashboard";
import SendBuyEmail from "../../containers/TicketActions/SendBuyEmail";
import GeneratePdfs from "../../containers/TicketActions/GeneratePdfs";
import UploadAvailableSeats from "../../containers/TicketActions/UploadAvailableSeats";
import Sell from "../../containers/Sell/Sell";
import Transactions from "../../containers/Transactions/Transactions";
import Statistics from "../../containers/Statistics/Statistics";
import SatsScan from "../../containers/SatsScan/SatsScan";
import SatsSells from "../../containers/SatsSells/SatsSells";
import MembershipManagment from "../../containers/MembershipManagment/MembershipManagment";
import TemplateEditor from "../../containers/TemplateEditor/TemplateEditor";
import EmailEditor from "../../containers/EmailEditor/EmailEditor";
import BalancePage from "../../containers/BalancePage/BalancePage";

const AuthorizedLayout = ({ location }) => {
  const shouldScanbitsDisplayed = location.pathname === ROUTES.PAYMENTS;

  return localStorage.getItem("user") ? (
    <Fragment>
      <Sidebar />
      {shouldScanbitsDisplayed && <Scanbits />}
      <Switch>
        <Route path={ROUTES.FAQ} component={Faq} />
        <Route path={ROUTES.ADD_EVENT} component={AddEvent} />
        <Route path={ROUTES.EVENTS} component={Events} />
        <Route path={ROUTES.EVENT} exact component={Event} />
        <Route
          path={ROUTES.EVENT_TEMPLATE_EDITOR}
          exact
          component={TemplateEditor}
        />
        <Route
          path={ROUTES.EVENT_EMAIL_EDITOR}
          exact
          component={EmailEditor}
        />
        <Route path={ROUTES.SCANTEAMS} exact component={ScanTeams} />
        <Route path={ROUTES.SCANTEAMS_ADD} exact component={AddTeams} />
        <Route path={ROUTES.SCANTEAMS_VIEW} exact component={AddTeams} />
        <Route path={ROUTES.TICKET_DETAIL} exact component={TicketDetail} />
        <Route path={ROUTES.PAYMENTS} component={Payments} />
        <Route path={ROUTES.ADD_TICKET} component={TicketDetail} />
        <Route path={ROUTES.PROMOTERS} component={Promoters} />
        <Route path={ROUTES.ADD_BAR_CODE} exact component={BarCodes} />
        <Route path={ROUTES.STATS} exact component={EventStatistics} />
        <Route path={ROUTES.TRANSACTIONS} exact component={Transactions} />
        <Route
          path={ROUTES.MEMBERSHIP_MANAGMENT}
          exact
          component={MembershipManagment}
        />

        <Route
          path={ROUTES.BALANCE}
          exact
          component={BalancePage}
        />
        {/* <Route path={ROUTES.DASHBOARD} exact component={Dashboard} /> */}
        <Route path={ROUTES.STATISTICS} exact component={Statistics} />
        <Route path={ROUTES.STATS_SCANNING} exact component={SatsScan} />
        <Route path={ROUTES.STATS_SELLING} exact component={SatsSells} />
        <Route path={ROUTES.SEND_BUY_EMAIL} exact component={SendBuyEmail} />
        <Route path={ROUTES.GENERATE_PDFS} exact component={GeneratePdfs} />
        <Route path={ROUTES.SELL} exact component={Sell} />
        <Route
          path={ROUTES.UPLOAD_AVAILABLE_SEATS}
          exact
          component={UploadAvailableSeats}
        />

        <Route path="/" component={Page404} />
      </Switch>
    </Fragment>
  ) : (
    <Redirect to="/login" />
  );
};

export default withRouter(AuthorizedLayout);
