import React, { Component } from "react";
import Container from "../../../components/UI/Container/Container";
import withRouter from "react-router/es/withRouter";
import Link from "react-router-dom/es/Link";

import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import "./AddTeams.sass";
import ChooseTicketList from "../../../components/ScanTeam/ChooseTicketList/ChooseTicketList";
import Icon from "../../../components/UI/Icon/Icon";
import CheckBox from "../../../components/UI/Checkbox/CheckBox";
import ROUTES from "../../../constants/routes";
import {
  createScanteam,
  createUserInfoSettings,
  deleteScanTeam,
  deleteUserInfoSettings,
  getAllEvents,
  getScanteam,
  getUserInfoSettings,
  refreshAccess,
  updateScanteam,
} from "../../../store/actions/scanteamsActions";
import connect from "react-redux/es/connect/connect";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";
import Clipboard from "react-clipboard.js";
import ClipboardJS from "clipboard";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import Page404 from "../../BarCodes/BarCodes";
import { toast } from "react-toastify";
import UserInfoSettingsContainer from "../../../components/UserInfoSettings/UserInfoSettingsContainer";
import NewLoader from "../../../components/NewLoader/NewLoader";
import { Breadcrumbs } from "../../../components/Breadcrumbs/Breadcrumbs";

const MySwal = withReactContent(Swal);
const QRCode = require("qrcode.react");
const AUTOSCAN_TYPE = {
  none: 1,
  autoscan: 2,
};
const SELL_TYPE = {
  none: 1,
  sellable: 2,
};

class AddTeams extends Component {
  _isMounted = false;
  _quickAccessLink = "";

  constructor(props) {
    super(props);
    this.state = {
      isView: !!this.props.match.params.id,
      allEvents: [],
      isDisabled: true,
      isSearchable: false,
      isMarkable: false,
      isAutoEntrance: false,
      scanteamId: "",
      scanteamName: "",
      loginToken: "",
      isFound: true,
      scanteamNameTitle: "",
      isSell: false,
      isScan: false,
      teamSellAbilityType: 1,
      additionalFields: [],
      loading: true,
    };
  }

  removeScanTeam = () => {
    MySwal.fire({
      title: "Are you sure that you want to delete this scanteam?",
      text: "It's impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "Delete",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.props
          .deleteScanTeam(this.props.user.token, this.props.match.params.id)
          .then(() => {
            this.props.history.push(ROUTES.SCANTEAMS);
          });
      }
    });
  };

  prepareEvents = (acc, item) => {
    acc.push({
      id: item.id,
      title: item.name,
      date:
        moment(item.startDate).format("DD MMM HH:mm") +
        " - " +
        moment(item.endDate).format("DD MMM HH:mm"),
      list: item.ticketTemplates,
    });
    return acc;
  };

  async componentDidMount() {
    this._isMounted = true;

    if (!this.state.isView) {
      this.props.getAllEvents(this.props.user.token).then((result) => {
        this.setState({
          allEvents: result && result.reduce(this.prepareEvents, []),
          loading: false,
        });
      });
    } else {
      await this.props
        .getScanteam(this.props.user.token, this.props.match.params.id)
        .then((scanteam) => {
          if (scanteam) {
            this.props
              .getAllEvents(this.props.user.token, scanteam.id)
              .then((events) => {
                const {
                  isUnscanAvailable,
                  loginToken,
                  id,
                  isSearchAvailable,
                  teamAutoscanAbilityType,
                  name,
                  teamSellAbilityType,
                  isScanAvailable,
                  isStatsAvailable
                } = scanteam;

                if (this._isMounted) {
                  this.setState({}, () =>
                    this.setState({
                      allEvents: events.reduce(this.prepareEvents, []),
                      isSearchable: isSearchAvailable,
                      isMarkable: isUnscanAvailable,
                      scanteamId: id,
                      scanteamName: name,
                      loginToken: loginToken,
                      isScan: isScanAvailable,
                      isStatsAvailable: isStatsAvailable,
                      isSell: teamSellAbilityType === SELL_TYPE.sellable,
                      isAutoEntrance:
                        teamAutoscanAbilityType === AUTOSCAN_TYPE.autoscan,
                      loading: false,
                    })
                  );

                  this._quickAccessLink =
                    "https://scannerenterxl.page.link/?link=https://com.enterXl.scanner/?barcode=" +
                    this.state.loginToken +
                    "&apn=com.enterXl.scanner&isi=1452427099&ibi=com.enterXl.scanner&ius=scanner";
                }
              });
          } else {
            this.setState({ isFound: false });
          }
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  isValid = () => {
    let validationMessage = null;
    let scanteamName = this.state.scanteamName;
    if (scanteamName.length < 3 || scanteamName.length > 50) {
      validationMessage =
        "The field Name must be a string with a minimum length of 3 and a maximum length of 50";
    }

    if (validationMessage) {
      Swal({
        title: "Error!",
        text: validationMessage,
        type: "error",
        timer: 5000,
      });
      return false;
    }

    return true;
  };

  checkTicket = (eventId, ticketId) => {
    let { allEvents, scanteamName } = this.state;
    const event = allEvents.find((event) => eventId === event.id);
    const ticket = event.list.find((ticket) => ticketId === ticket.id);
    ticket.isScanning = !ticket.isScanning;
    this.setState({
      allEvents,
    });
    if (scanteamName.length > 0) {
      this.setState({ isDisabled: false });
    }
  };

  checkboxChange = (evt) => {
    const target = evt.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const related = target.dataset.related;

    let { scanteamName } = this.state;
    if (scanteamName.length > 0) {
      this.setState({ isDisabled: false });
    }

    this.setState({
      [name]: value,
    });

    if (related) {
      const relatedArr = related.split(",");

      relatedArr.forEach((item) => {
        this.setState({
          [item]: this.state[item] && false,
        });
      });
    }
  };

  inputChangeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.setState({ isDisabled: event.target.value.length === 0 });
  };

  addAdditionalField = (newField) =>
    this.setState({
      additionalFields: [...this.state.additionalFields, newField],
    });

  delAdditionalField = (localId) => {
    const filteredArrOfAdditionalFields = this.state.additionalFields.filter(
      (field) => field.id !== localId
    );
    this.setState({ additionalFields: [...filteredArrOfAdditionalFields] });
  };

  saveHandler = () => {
    const {
      isSearchable,
      isAutoEntrance,
      isView,
      isSell,
      isMarkable,
      isScan,
      scanteamName,
      allEvents,
    } = this.state;
    const ticketTemplatesIds = allEvents.reduce((acc, value) => {
      value.list.forEach((item) => {
        if (item.isScanning) {
          acc.push(item.id);
        }
      });
      return acc;
    }, []);

    const outputData = {
      name: scanteamName,
      ticketTemplatesIds,

      isSearchAvailable: isSearchable,
      isUnscanAvailable: isMarkable,
      isStatsAvailable: true,
      isScanAvailable: isScan,
      teamSellAbilityType: isSell ? SELL_TYPE.sellable : SELL_TYPE.none,
      teamAutoscanAbilityType: isAutoEntrance
        ? AUTOSCAN_TYPE.autoscan
        : AUTOSCAN_TYPE.none,
    };

    if (this.isValid()) {
      if (isView) {
        this.props.updateScanteam(
          this.props.user.token,
          outputData,
          this.props.match.params.id
        );
      } else {
        this.props
          .createScanteam(this.props.user.token, outputData)
          .then((result) => {
            if (result) {
              this.state.additionalFields.length &&
                this.props.createUserInfoSettings(
                  this.props.user.token,
                  this.state.additionalFields,
                  result.id
                );

              this.props.history.push(ROUTES.SCANTEAMS);
            }
          });
      }
    }
  };

  refreshAccessScanteam = () => {
    const { token } = this.props.user;
    const { scanteamId } = this.state;
    const { refreshAccess } = this.props;

    refreshAccess(token, scanteamId).then((result) => {
      this.setState({ loginToken: result.loginToken });
    });
  };

  linkCopied = () => {
    toast.dismiss(257);
    toast.success("Quick access link was copied to clipboard.", {
      toastId: 257,
    });
  };

  showExplanatoryMessage = () => {
    MySwal.fire({
      title:
        "Please, send this link to your Scanner team, so they can enter the mobile app.",
      showConfirmButton: true,
      confirmButtonText: "Copy link",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const confirmBtn = MySwal.getConfirmButton();
        new ClipboardJS(`.${confirmBtn.getAttribute("class").split(" ")[0]}`);
        confirmBtn.setAttribute("data-clipboard-text", this._quickAccessLink);
        this.linkCopied();
      }
    });
  };

  render() {
    if (!this.state.isFound) {
      return <Page404 />;
    }

    const {
      isView,
      isSearchable,
      isMarkable,
      scanteamName,
      loginToken,
      isDisabled,
      isAutoEntrance,
      isScan,
      isSell,
      allEvents,
      scanteamId,
      additionalFields,
      loading,
    } = this.state;
    let title = !isView ? "Add scanteam" : scanteamName;

    const fullPath = [
      {
        path: ROUTES.SCANTEAMS,
        name: "Scanteams",
      },
      {
        name: title,
      },
    ];

    return loading ? (
      <NewLoader className='loader-add-teams' />
    ) : (
      <Container className="add-scan-team">
        <PageTitle>
          <Breadcrumbs routes={fullPath} />
        </PageTitle>

        <MobileHeader>{title}</MobileHeader>

        <div className="add-event__block">
          <label>Scanteam name</label>
          <input
            placeholder="Enter scanteam name"
            type="text"
            name="scanteamName"
            defaultValue={scanteamName}
            onChange={this.inputChangeHandler}
          />
        </div>

        <div className="add-scan-team__body">
          <ChooseTicketList items={allEvents} checkTicket={this.checkTicket} />

          {(scanteamId || !isView) && (
            <UserInfoSettingsContainer
              getUserInfoSettings={this.props.getUserInfoSettings}
              createUserInfoSettings={this.props.createUserInfoSettings}
              deleteUserInfoSettings={this.props.deleteUserInfoSettings}
              scanTeamId={scanteamId}
              userToken={this.props.user.token}
              userInfoSettings={
                isView
                  ? this.props.scanteams.userInfoSettings
                  : additionalFields
              }
              isTeamExisting={isView}
              delAdditionalField={this.delAdditionalField}
              addAdditionalField={this.addAdditionalField}
            />
          )}

          {isView && (
            <div className="qr">
              <QRCode value={loginToken} size={180} />
              <div className="link-wrapper">
                <p className="qr-link" onClick={this.showExplanatoryMessage}>
                  Quick access link
                </p>
                <Clipboard
                  className="copy-app-link"
                  data-clipboard-text={this._quickAccessLink}
                  onClick={this.linkCopied}
                >
                  <Icon name="copy-pink" />
                </Clipboard>
              </div>
            </div>
          )}
        </div>

        <div className="page-footer">
          <div className="checkboxes">
            <div className="checkboxes-item">
              <CheckBox
                name="isSell"
                id="is-sell"
                checked={isSell}
                onChange={this.checkboxChange}
                related={["isAutoEntrance"]}
              >
                Sell
              </CheckBox>
            </div>
            <div className="checkboxes-item custom-margin-left">
              <CheckBox
                name="isScan"
                id="is-scan"
                checked={isScan}
                onChange={this.checkboxChange}
                related={["isSearchable", "isMarkable"]}
              >
                Scan
              </CheckBox>
            </div>
          </div>
          <div className="checkboxes">
            <div className="checkboxes-item">
              <CheckBox
                name="isAutoEntrance"
                id="is-autosell"
                checked={isAutoEntrance}
                onChange={this.checkboxChange}
                isDisabled={!isSell}
              >
                Auto entrance
              </CheckBox>
            </div>
          </div>
          <div className="checkboxes">
            <div className="checkboxes-item">
              <CheckBox
                name="isSearchable"
                id="is-searchable"
                checked={isSearchable}
                onChange={this.checkboxChange}
                related={["isMarkable"]}
                isDisabled={!isScan}
              >
                Search ability
              </CheckBox>
            </div>
            <div className="checkboxes-item">
              <CheckBox
                name="isMarkable"
                id="is-markable"
                checked={isMarkable}
                onChange={this.checkboxChange}
                isDisabled={!isSearchable}
              >
                Ability to mark as unscanned
              </CheckBox>
            </div>
          </div>
          <div className="buttons">
            {isView && (
              <button
                type="button"
                className="btn-secondary"
                onClick={this.refreshAccessScanteam}
              >
                <Icon name="refresh" />
                <span>Refresh access</span>
              </button>
            )}
            {isView && (
              <button
                className="btn-default"
                type="button"
                onClick={this.removeScanTeam}
              >
                <Icon name="trash" />
                <span>Delete</span>
              </button>
            )}
            <button
              type="button"
              className="btn-primary"
              onClick={this.saveHandler}
              disabled={isDisabled || (!isSell && !isScan)}
            >
              <Icon name="save" />
              <span>Save</span>
            </button>
          </div>
        </div>
      </Container>
    );
  }
}

AddTeams.propTypes = {};

const mapStateToProps = ({ scanteams, auth: { user } }) => ({
  scanteams,
  user,
});

const mapDispatchToProps = (dispatch) => ({
  deleteScanTeam: (token, id) => dispatch(deleteScanTeam(token, id)),
  getAllEvents: (token, scanTeamId = 0, skip = 0, take = 0) =>
    dispatch(getAllEvents(token, scanTeamId, skip, take)),
  createScanteam: (token, data) => dispatch(createScanteam(token, data)),
  updateScanteam: (token, data, id) =>
    dispatch(updateScanteam(token, data, id)),
  getScanteam: (token, id) => dispatch(getScanteam(token, id)),
  refreshAccess: (userToken, scanteamId) =>
    dispatch(refreshAccess(userToken, scanteamId)),
  getUserInfoSettings: (userToken, scanteamId) =>
    dispatch(getUserInfoSettings(userToken, scanteamId)),
  createUserInfoSettings: (userToken, data, scanteamId) =>
    dispatch(createUserInfoSettings(userToken, data, scanteamId)),
  deleteUserInfoSettings: (userToken, teamConfigurationId, scanteamId) =>
    dispatch(
      deleteUserInfoSettings(userToken, teamConfigurationId, scanteamId)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddTeams));
